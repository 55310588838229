import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const options = {
      backdrop: 'dynamic',
      backdropClasses: 'backdrop-blur-sm bg-gray-600 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-[60]'
    }

    this.modal = new Modal(this.element, options)
    this.modal.show()
  }

  submitEnd(event) {
    if (event.detail.success) {
      this.modal.hide()
    }
  }

  hide() {
    this.modal.hide()
  }

  checkEsc(event) {
    if (event.keyCode == 27) {
      this.modal.hide()
    }
  }
}