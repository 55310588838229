import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["parent", "child"];
  static values = {
    show: String
  }


  connect() {
    // Hide the target element initially
    //this.toggleVisibility();
  }

  toggleChild(event) {
    const selectedText = event.target.selectedOptions[0].textContent
    console.log(this.showValue)
    if (selectedText == this.showValue) {
      this.childTarget.classList.remove("hidden");
    } else {
      this.childTarget.classList.add("hidden");}
  }
}
