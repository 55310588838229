import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.fadeOut();
    }, 1500);
  }

  fadeOut() {
    // fade the flash out
    this.element.classList.add("transition", "ease-in", "duration-500", "opacity-0")

    // remove completely from the dom after fadeout
    setTimeout(() => {
      this.element.remove()
    }, 500);
  }
}
