import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce"

export default class extends Controller {
  static targets = [ "selectedProject", "input", "card" ]

  static values = {
    projectListHidden: String,
    projectList: String,
    projectSelectUrl: String,
    projectSearchUrl: String,
    projectSearchResults: String
  }

  initialize() {
    this.searchProjects = debounce(this.searchProjects, 200).bind(this)
  }

  searchProjects(event) {
    let projectSearchUrl      = `${this.projectSearchUrlValue}?query=${event.target.value}`
    let projectSearchResults  = document.getElementById(this.projectSearchResults)

    // Get the Search Results and render on the page
    this.fetchProjectsContent(projectSearchUrl, projectSearchResults, "replace")
  }


  selectProject(event) {
    event.preventDefault();

    // Set the selected font to a var
    let selectedProject       = event.currentTarget
    let projectList           = document.getElementById(this.projectList)
    let projectID             = selectedProject.getAttribute("data-project-id")
    let projectSelectUrl      = this.projectSelectUrl.replace(":id", projectID)

    // console.log(projectSelectUrl)
    // console.log(projectID)

    // Add this project ID to the hidden field of projects
    this.addHiddenProjectToDom(projectID)

    // Render the Project Card on the page
    this.fetchProjectsContent(projectSelectUrl, projectList, "append")

    this.hideProjectSearchResults()
  }

  fetchProjectsContent(url, destination, action) {
    fetch(url)
      .then((r) => r.text())
      .then((html) => {
        const fragment = document
          .createRange()
          .createContextualFragment(html)

          if (action == "append") {
            destination.appendChild(fragment)
          }
          else {
            destination.replaceChildren(fragment)
          }
        })
  }

  hideProjectSearchResults(event){
    let projectSearchResults  = document.getElementById(this.projectSearchResults)
    projectSearchResults.replaceChildren('')
    this.inputTarget.value = ''
  }

  removeProject(event) {
    event.preventDefault();
    let selectedProjectCard = event.target.closest('div.sort-handle')
    let selectedProjectID = selectedProjectCard.getAttribute("data-project-id")
    selectedProjectCard.classList.remove("opacity-100")
    selectedProjectCard.classList.add("opacity-0", "transition", "ease-in", "duration-200",)

    //fade our card
    setTimeout(function(){
      selectedProjectCard.remove();
    }, 200)

    // remove all instances of this project from the hidden form field
    const hiddenProjectListContainer = document.querySelector("#project-list-hidden")
    const collection = hiddenProjectListContainer.querySelectorAll(`[data-project-id="${selectedProjectID}"]`)
    for (const elem of collection) {
      elem.remove()
    }
  }

  addHiddenProjectToDom(projectID) {
    let projectListHidden  = document.getElementById(this.projectListHidden)
    let hiddenProject = document.createElement("input")

    hiddenProject.name = "section[project_ids][]"
    hiddenProject.id="section_project_ids"
    hiddenProject.setAttribute('data-project-id', projectID)
    hiddenProject.setAttribute('multiple', '')
    hiddenProject.value = projectID
    hiddenProject.autocomplete = "off"
    hiddenProject.type = "hidden"

    projectListHidden.appendChild(hiddenProject)
  }

  checkEsc(event) {
    if (event.keyCode == 27) {
      this.hideProjectSearchResults()
    }
  }

  get projectList(){
    return this.projectListValue
  }

  get projectSelectUrl(){
    return this.projectSelectUrlValue
  }

  get projectListHidden(){
    return this.projectListHiddenValue
  }

  get projectSearchResults(){
    return this.projectSearchResultsValue
  }

}
