import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "selectedFont", "visibleFont", "dropdown", "hiddenField" ]

  static values = {
    googleFontsUrl: String,
    activeFontName: String,
    activeFontClass: String,
    preloadFonts: Boolean
  }

  connect() {
    // set the value of the DropDown Menu to the current font from the DB
    this.updateDropdownMenu(this.activeFontNameValue, this.activeFontClassValue)
  }

  selectFont(event) {
    // make sure we don't follow the default behavior of following the "link" in the clicked <a>
    event.preventDefault()

    // Set the selected font to a var
    let selectedFont = event.target

    // set the value of the hidden font field to the UUID of the font
    this.hiddenFieldTarget.value = event.params.id

    // update the selected text displayed in the Drop Down menu to the newly selected Font
    this.updateDropdownMenu(event.params.fontName, event.params.fontClass)

    // Loop through and remove the active class from any font
    this.selectedFontTargets.forEach(target => {
     target.classList.remove("bg-gray-100")
    })

    // set the selected font class to be active
    selectedFont.classList.add("bg-gray-100")

    // hide the font list after selecting the font
    this.dropdownTarget.classList.add("hidden")
  }


  updateDropdownMenu(font_name, font_class) {
    if (font_name){
      // Set the text of the dropdown menu to the selected font
      this.visibleFontTarget.innerText = font_name

      // Set the style of the dropdown menue to the selected font
      this.visibleFontTarget.className = font_class
    }else{
      this.visibleFontTarget.innerText = "Select a font"
    }
  }

  /**
   * Dynamically loads the remote fonts in the <head> tag in order to style the dropdown
   */
  loadExternalFontsInHeader(){
    // we set preloadFontsValue at the stimulus controller level so we don't do this when we have multiple font selectors
    if(this.preloadFontsValue){
      const googleFontsUrl = this.googleFontsUrlValue;
      const fonts = this.selectedFontTargets
      const pHead = document.getElementsByTagName("head")[0];

      // Iterate over fonts
      fonts.forEach((font) => {
        let isGoogle = font.getAttribute("data-font-selector-is-google-param")

        if (isGoogle != "true"){
          return
        }

        let fontEncoded   = font.getAttribute("data-font-selector-font-encoded-param")
        let fontEncodedId = `font-${fontEncoded}`

        // Add external font link to header
        if(!(document.getElementById(fontEncodedId))){
          let headFont = document.createElement("link");
          headFont.setAttribute("id", fontEncodedId)
          headFont.setAttribute("href", `${googleFontsUrl}?family=${fontEncoded}`);
          headFont.setAttribute("rel", "stylesheet");
          pHead.appendChild(headFont);
        }
      });
    }
  }


}
