import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce"

export default class extends Controller {
  static targets = [ "input", "list", "results", "hidden", "x" ]

  static values = {
    searchUrl: String,
    searchType: String
  }

  initialize() {
    this.search = debounce(this.search, 200).bind(this)
  }

  search(event) {
    if (event.target.value == ''){
      this.hiddenTarget.value = ''
    }else{
      let url      = `${this.searchUrlValue}?query=${event.target.value}&type=${this.searchTypeValue}`
      let results  = this.resultsTarget

      // Get the Search Results and render on the page
      this.fetchResults(url, results, "replace")
    }
  }

  select(event) {
    event.preventDefault();

    let selected            = event.currentTarget
    this.hiddenTarget.value = selected.getAttribute("data-id")
    this.inputTarget.value  = selected.getAttribute("data-name")
    this.hideSearchResults()
  }

  fetchResults(url, destination, action) {
    fetch(url)
      .then((r) => r.text())
      .then((html) => {
        const fragment = document
          .createRange()
          .createContextualFragment(html)

          if (action == "append") {
            destination.appendChild(fragment)
          }
          else {
            destination.replaceChildren(fragment)
            this.xTarget.classList.remove("invisible")
            this.xTarget.classList.remove("visible")
          }
        })
  }

  close(event) {
    event.preventDefault()
    this.inputTarget.value = ''
    this.hiddenTarget.value = ''
    this.hideSearchResults()
  }

  hideSearchResults(){
    this.resultsTarget.replaceChildren('')
    this.xTarget.classList.remove("visible")
    this.xTarget.classList.add("invisible")
  }

  checkEsc(event) {
    if (event.keyCode == 27) {
      this.hideSearchResults()
    }
  }
}
