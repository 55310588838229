import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "visible", "is_cover" ]
  static values = { updateUrl: String, formField: String}
  connect() {
  }


  toggle(event) {
    let formData = new FormData()
    const token = document.getElementsByName("csrf-token")[0].content;
    formData.append(this.formFieldValue, this.visibleTarget.checked);

    fetch(this.updateUrlValue, {
      body: formData,
      method: 'PATCH',
      credentials: "include",
      dataType: "script",
      headers: {
              "X-CSRF-Token": token
        },
    })

    .then(function(response) {
      if (response.status != 200) {
        event.target.checked = event.target.checked
      }
    })
  }


  set_cover(event) {
    try {
      const formData = new FormData();
      const token = document.getElementsByName("csrf-token")[0].content;
      formData.append("project_image[is_cover]", this.is_coverTarget.checked);

      fetch(this.updateUrlValue, {
        body: formData,
        method: 'PATCH',
        credentials: "include",
        dataType: "script",
        headers: {
          "X-CSRF-Token": token,
          Accept: "text/vnd.turbo-stream.html",
        },
      })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
      .catch(error => {
        console.error('Error:', error);
        // Display an error message to the user
      });

      const checkboxes = document.querySelectorAll('[id*="hero-toggle"]')
      checkboxes.forEach(checkbox => {
        if (this.is_coverTarget !== checkbox){
          checkbox.checked = false;
        }
      });
    } catch (error) {
      console.error('Error:', error);
      // Display an error message to the user
    }
  }
}