import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "menu", "button" ]


  connect() {
    const options = {
      placement: "bottom"
    }

    this.dropdown = new Dropdown(this.menuTarget, this.buttonTarget, options);
  }
}
