import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "content" ]

  connect() {
  }

  show() {
    this.contentTarget.classList.remove("invisible")
  }

  hide() {
    this.contentTarget.classList.add("invisible")
  }
}
