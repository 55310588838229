import { Controller } from "@hotwired/stimulus"
import { FileInput, Informer, ProgressBar, ThumbnailGenerator, DragDrop } from "uppy"
import { uppyInstance, uploadedFileData } from "../src/uppy"

export default class extends Controller {
  static targets = [ 'input', 'result', 'preview', 'remove']
  static values = { types: Array, server: String, buttontext: String, maxFiles: String, maxFileSize: Number  }

  connect() {
    //this.inputTarget.classList.add('invisible')
    this.uppy = this.createUppy()
    this.attach()
  }

  disconnect() {
    this.uppy.close()
  }

  createUppy() {
    const uppy = uppyInstance({
        id: this.inputTarget.id,
        types: this.typesValue,
        server: this.serverValue,
        maxFiles: this.maxFilesValue,
        maxFileSize: this.maxFileSize
      })
      // .use(FileInput, {
      //   target: this.inputTarget.parentNode,
      //   locale: { strings: { chooseFiles: this.buttontextValue } },
      // })
      .use(Informer, {
        target: '.UploadForm',
      })
      .use(ProgressBar, {
        target: this.previewTarget,
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 400,
      })

    uppy.on('upload-success', (file, response) => {
      // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
      this.resultTarget.value = uploadedFileData(file, response, this.serverValue)
    })

    //This may be interesting, but as of now, causes a console error...
    uppy.on('thumbnail:generated', (file, preview) => {
      this.previewTarget.src = preview
      this.previewTarget.classList.add("visible", "h-16", "w-16", "object-cover", "rounded", "mr-4")
      this.previewTarget.classList.remove("invisible")
      this.removePreviewTarget.classList.add("visible")
      this.removePreviewTarget.classList.remove("invisible")

    })

    return uppy
  }

  delete(event) {
    event.preventDefault()
    event.stopImmediatePropagation()
    if ((window.confirm("Are you sure?"))) {
      let deleteURL = event.target.getAttribute("data-delete-url")
     // let data = new FormData()
      let token = document.querySelector("meta[name=csrf-token]").getAttribute("content")
      const request = new XMLHttpRequest()
      request.open('DELETE', deleteURL, true)
      request.setRequestHeader('X-CSRF-Token', token)
      request.send() //request.send(data) # removed data as it was causing an error - there is no data to send

      //set the preview SRC to nil
      this.previewTarget.classList.add("invisible")
      this.removeTarget.classList.add("invisible")
      this.previewTarget.classList.remove("visible")
      this.removeTarget.classList.remove("visible")
    }


  }

  attach() {
    this.inputTarget.addEventListener('change', (event) => {
      const files = Array.from(event.target.files)

      files.forEach((file) => {
        try {
          this.uppy.addFile({
            source: 'file input',
            name: file.name,
            type: file.type,
            data: file,
          })
        } catch (err) {
          if (err.isRestriction) {
            // handle restrictions
            console.log('Restriction error:', err)
          } else {
            // handle other errors
            console.error(err)
          }
        }
      })
    })
  }
}
