import { Controller } from "@hotwired/stimulus"
import { Sortable } from "sortablejs"

export default class extends Controller {
  static targets = [ "source", "destination" ]
  static values = {
    section: String,
    section_question: String
  }

  connect() {
    this.sortable = Sortable.create(this.sourceTarget, {
      group: "questions",
      pull: true,
      put: false,
      sort: false,
      scroll: true,
      scrollSensitivity: 30, // px, how near the mouse must be to an edge to start scrolling.
      scrollSpeed: 10, // px, speed of the scrolling
      bubbleScroll: true, // apply autoscroll to all parent elements, allowing for easier movement
      revertOnSpill: true,
      ghostClass: "sortable-ghost",
      swapThreshold: 0.5,
      animation: 150,
      easing: "cubic-bezier(1, 0, 0, 1)"
    })

    this.sortable = Sortable.create(this.destinationTarget, {
      group: "questions",
      pull: false,
      put: true,
      scroll: true,
      scrollSensitivity: 30, // px, how near the mouse must be to an edge to start scrolling.
      scrollSpeed: 10, // px, speed of the scrolling
      bubbleScroll: true, // apply autoscroll to all parent elements, allowing for easier movement
      revertOnSpill: true,
      ghostClass: "sortable-ghost",
      swapThreshold: 0.5,
      animation: 150,
      easing: "cubic-bezier(1, 0, 0, 1)",
      onAdd: this.add.bind(this),
      onRemove: this.remove.bind(this),
      onSort: this.sort.bind(this)
    })

  }

  add(event) {
    let question_id = event.item.id
    let section_id = this.sectionValue
    let formData = new FormData()
    formData.append("section_question[question_id]", question_id)
    formData.append("section_question[section_id]", section_id)
    formData.append("section_question[position]", event.newIndex + 1)


    const csrfToken = document.querySelector("[name='csrf-token']").content
    fetch('/app/section_questions',  {
      body: formData,
      method: 'POST',
      credentials: "include",
      dataType: "script",
      headers: {
              "X-CSRF-Token": csrfToken
        },
    })

    .then(function(response) {
      return response.json()
    })

    .then(function(data) {
      event.item.id = data.id
    })

    .catch(function(error) {
      console.error('Error:', error)
    });
  }

  remove(event) {
    let section_question_id = event.item.id

    const csrfToken = document.querySelector("[name='csrf-token']").content
    fetch(`/app/section_questions/${section_question_id}`,  {
      method: 'DELETE',
      credentials: "include",
      dataType: "script",
      headers: {
              "X-CSRF-Token": csrfToken
        },
    })

    .then(function(response) {
      return response.json()
    })

    .then(function(data) {
      console.log(data) // Log the parsed JSON response
      event.item.id = data.id
    })

    .catch(function(error) {
      console.error('Error:', error)
    });
  }


  sort(event) {
    let section_question_id = event.item.id
    const csrfToken = document.querySelector("[name='csrf-token']").content

    let formData = new FormData()
    formData.append("section_question[id]", section_question_id)
    formData.append("section_question[position]", event.newIndex + 1)

    fetch(`/app/section_questions/${section_question_id}/move`,  {
      body: formData,
      method: 'PATCH',
      credentials: "include",
      dataType: "script",
      headers: {
              "X-CSRF-Token": csrfToken
        },
    })

    .then(function(response) {
    //  return response.json()
    })

    .catch(function(error) {
      console.error('Error:', error)
    });
  }






}