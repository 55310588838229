import { Controller } from "@hotwired/stimulus"
import Coloris from "@melloware/coloris";

export default class extends Controller {
  static values = {
    colors: String
  }

  initialize() {
    Coloris.init();
    Coloris.coloris({
      el: '.color-field',
        swatches: this.colorsValue.split(','),
        theme: 'polaroid',
        focusInput: false,
        alpha: false
     });
  }

  disconnect() {
    Coloris.close();
  }
}

