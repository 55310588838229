import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "results" ]
  static values = {
    page: String
  }

  connect() {
  }

  load_form(event) {
    var form_name = event.target.selectedOptions[0].value
    let url      = `/app/pages/${this.pageValue}/sections/new?type=${form_name}`
    let results  = this.resultsTarget
    this.fetchResults(url, results, "replace")
  }

  fetchResults(url, destination, action) {
    fetch(url)
      .then((r) => r.text())
      .then((html) => {
        const fragment = document
          .createRange()
          .createContextualFragment(html)

          if (action == "append") {
            destination.appendChild(fragment)
          }
          else {
            destination.replaceChildren(fragment)
          }
        })
  }
}
