import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "field", "latitude", "longitude", "address_data", "city", "state", "state_abbreviated", "country", "country_abbreviated" ]

  connect() {
    if (typeof(google) != 'undefined') {
      this.initAutoSuggest()
    }
  }

  initAutoSuggest() {
    /* added region=us to restrict to US only - this prevents "USA" from being appended to each city in the lookup */
    const options = {
      fields: ['address_components', 'geometry', 'name', 'formatted_address'],
      types: ["(regions)"],
    };

    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget, options)
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
  }

  placeChanged(){
    let place = this.autocomplete.getPlace();

    if (!place.geometry) {
      return
    }

    var address_components_by_type = {}
    for (var i = 0; i < place.address_components.length; i++) {
      var c = place.address_components[i]
      address_components_by_type[c.types[0]] = c
    }

    this.latitudeTarget.value = place.geometry.location.lat()
    this.longitudeTarget.value = place.geometry.location.lng()
    this.cityTarget.value = address_components_by_type.locality.long_name
    this.stateTarget.value = address_components_by_type.administrative_area_level_1.long_name
    this.state_abbreviatedTarget.value = address_components_by_type.administrative_area_level_1.short_name
    this.countryTarget.value = address_components_by_type.country.long_name
    this.country_abbreviatedTarget.value = address_components_by_type.country.short_name
    this.address_dataTarget.value = JSON.stringify(address_components_by_type)
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault()
    }
  }
}
