import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const options = {
      placement: 'right',
      backdrop: true,
      backdropClasses: 'backdrop-blur-sm bg-gray-600 bg-opacity-50 fixed inset-0 z-[60]'
    }

    this.offcanvas = new Drawer(this.element, options)
    this.offcanvas.toggle()
  }

  hide() {
    this.offcanvas.hide()
  }

  submitEnd(event) {
    // if (event.detail.success) {
    //   this.offcanvas.hide()
    // }
  }

  checkEsc(event) {
    if (event.keyCode == 27) {
      this.offcanvas.hide()
    }
  }
}



