import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce"

export default class extends Controller {
  static get targets() { return [ "input" ] }

  initialize() {
    this.submit = debounce(this.submit.bind(this), 400)
  }

  submit() {
    //this.submitTarget.click()
    this.element.requestSubmit()
  }

  hideValidationMessage(event) {
    event.stopPropagation()
    event.preventDefault()
  }

  checkEsc(event) {
    if (event.keyCode == 27) {
      this.inputTarget.value = ''
      this.submit()
    }
  }
}